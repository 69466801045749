
import React, { Component } from 'react';
import './App.css';

class Faq extends Component {

	async componentWillMount() {



	}

	constructor(props) {
		super(props);
		this.state = { 
			account: '',
			balance: '',
			contract: null,
			totalSupply: 0,
			colors: [],
			owners: [],
			tokens: [],
			to: '',
			sellPrice: '',
			tokenToTransfer: '',
			selectedFile: null,
			metaImage: [],
            metaName: [],
            metaDescription: [],
            breakpointColumnsObj: {
		        default: 3,
		        1100: 1,
		        700: 1,
		        500: 1
		      }
		}
  	}


	render() {
		return (



			<div className="wrapper">


				<div className="main">

					<section className="py-5 text-center container hero">
					    <div className="row py-lg">
					      <div className="col-lg-12 col-md-12 mx-auto">
					        <h1 className="fw-light">Frequently asked questions.</h1>
					        <p className="lead text-muted">If you still need more answers, shoot us a quick email to contact@moonbear.com</p>
					      </div>
					    </div>
					  </section>

					<div className="content">
						<div className="container p-0">

					  		<div className="row" data-masonry='{"percentPosition": true }'>
						  		<div className="col-md-6 offset-3">
						  			<ul className="faq">
						  				<li>
											<h2>What is an NFT?</h2>
											<p>A non-fungible token (NFT) allows the buyer to own a unique digital item. Items that can be tokenised include photos, videos, audio, and more. An NFT not only allows the buyer to own an original item, but that item will also contain built-in authentication, which serves as proof of ownership. Many collectors value the exclusive ownership rights as much as the item itself. It is essentially a certificate of authenticity, proving that you own the right to use the digital item (eg, print and display the item on your wall, keep it in your digital wallet, etc). NFTs can only have one official owner at a time, and they are kept secure by the Ethereum blockchain. No one can modify the record of ownership, or copy/paste a new NFT into existence.</p>
											</li>

											<li>
											<h2>What is minting?</h2>
											<p>Minting is the process of certifying your digital creation into the blockchain. Similar to the way that metal coins are minted and added into circulation, your NFT would be tamper-proof and immune to any modifications. Once you have minted your NFT, you have created an official certificate of authenticity. You can then sell this certificate (NFT) or keep it as part of your collection.</p>
											</li>

											<li>
											<h2>How much does it cost to mint my artwork?</h2>
											<p>We don't charge you anything to mint your work. The only cost you have to pay is the gas fee, which is distributed to the miners on the Ethereum blockchain. This can range anywhere from $50 to $70 USD depending on how busy the network is. Click here, to learn more about gas fees.</p>
											</li>

											<li>
											<h2>How do I purchase an NFT?</h2>
											<p>To purchase an NFT, you’ll need a wallet with Ethereum, a type of cryptocurrency used to make transactions on the Ethereum blockchain. You can download the MetaMask chrome extension and set up a wallet there if you don't already have one.</p>
											</li>

											<li>
											<h2>What do I do after I have successfully purchased an NFT?</h2>
											<p>The NFT is now a part of your collection! You can do anything you want with it. Keep it as a part of your collection, show it off to your friends, or even sell it later at a higher price.</p>
											</li>

											<li>
											<h2>Do you take a commission from the sales?</h2>
											<p>Yes, we take a 5% commission from every completed sale to cover our expenses on the platform.</p>
											</li>

											<li>
											<h2>What are royalties?</h2>
											<p>Royalties are payments made to a creator when their NFT is resold. You can set up your commission fee for when your NFT is resold. The commission fee is permanent and can never be changed be changed. As a creator you can be assured that you will always be paid your commision.</p>
											<p><b>Here is an example:</b></p>
											<p>- You have set a royalty of 20% on your NFT.</p>
											<p>- Bob buys your NFT for $10, and he later sells that NFT for $20.</p>
											<p>- You would receive 20% of the $20. Royalties are paid automatically into your wallet and are a part of your NFT forever.</p>
											</li>

											<li>
											<h2>How does the auction work?</h2>
											<p></p>
											<h4>Starting the auction</h4>
											<p>You can put your digital item up for auction on any NFT market after you've minted it. You can also cancel your auction if there aren't any bids. The 24 hour timer begins after the first bid has been placed. If a bid is placed within the final 10 minutes, the auction gets extended by another 10 minutes.</p>
											<h4>Bidding</h4>
											<p>After you place a bid, your Ethereum is safely transferred into the Auction Contract wallet, which acts as the escrow. If you are outbid, the Ethereum will be refunded to you instantly. If you win the bid, the Ethereum will be transferred to the seller, and the NFT will be transferred to your Auction Contract wallet.</p>
											</li>

											<li>
											<h2>What is the point of an NFT?</h2>
											<p>The future is unpredictable, however one thing is for certain. Your NFT will exist forever. It cannot be modified or tampered with in any way, and it will always be linked to you, the creator. The NFT can be sold and passed down to many different people in the future, but the creator is always credited permanently.</p>
											</li>

						  			</ul>
						  		</div>
					  		</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Faq;