// src/Header.js

import React from 'react'

function Footer() {
  return (
  	<footer className="footer">
				<div className="container-fluid">
					<div className="row text-muted">
						<div className="col-6 text-start">
							<ul className="list-inline">
								<li className="list-inline-item">
									<i className="far fa-envelope"></i> <a className="text-muted" href="https://moonbear.com/contact">Contact</a>
								</li>
								<li className="list-inline-item">
									<i className="far fa-address-card"></i> <a className="text-muted" href="https://moonbear.com/about">About</a>
								</li>
								<li className="list-inline-item">
									<i className="fab fa-discord"></i> <a className="text-muted" href="https://discord.com/invite/build">Discord</a>
								</li>
								<li className="list-inline-item">
									<i className="far fa-question-circle"></i> <a className="text-muted" href="/faq">FAQ</a>
								</li>
							</ul>
						</div>
						<div className="col-6 text-end">
							<p className="mb-0">
								© 2021 - <a href="https://moonbear.com" className="text-muted footer-logo">MOONBEAR <img alt="" src="/logo-dark.png"/></a>
							</p>
						</div>
					</div>
				</div>
			</footer>
  )
}

export default Footer