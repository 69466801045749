
import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import Token  from '../abis/Token.json';
import TokenAuction  from '../abis/TokenAuction.json';
import Masonry from 'react-masonry-css'
import Countdown from 'react-countdown';
import NFTImage from './NFTImage';
import axios from 'axios';

class Home extends Component {

     constructor(props) {
        super(props);
        this.state = { 
            account: '',
            balance: '',
            contract: null,
            totalSupply: 0,
            colors: [],
            owners: [],
            tokens: [],
            to: '',
            sellPrice: '',
            tokenToTransfer: '',
            selectedFile: null,
            metaImage: [],
            metaName: [],
            metaDescription: [],
            breakpointColumnsObj: {
                default: 3,
                1100: 1,
                700: 1,
                500: 1
              },
            totalOwned: 0,
            metamask: '',
        }
    }

    async componentWillMount() {

        // Load web3
        await this.loadWeb3()

        // Load blockchain data
        await this.loadBlockchaindata()

        // Authenticate pinata (IPFS)
        const pinataSDK = require('@pinata/sdk');
        const pinata = pinataSDK('0f4821963f69d9cda862', 'bce7b92bbb6f573171de3f8f084b09e3dae108257395627b945e3fc73f4d366a');

        pinata.testAuthentication().then((result) => {
            //handle successful authentication here

        }).catch((err) => {
            //handle error here
            console.log(err);
        });

    }

      async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            this.setState({metamask: "true"})
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            this.setState({metamask: "true"})
        } else {
            window.web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/723ee4d221864874b81cd8c6f7ae6b5f'))
            // window.web3 = new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:7545'))
             this.setState({metamask: "false"})
        }
    }
    
    async loadBlockchaindata() {

        const web3 = window.web3
        // load account
        if (this.state.metamask == "true") {
            //load account
            const accounts = await web3.eth.getAccounts()
            if (accounts.length > 0) {
                const balance = await web3.eth.getBalance(accounts[0])
                this.setState({
                    account: accounts[0],
                    balance: balance
                });
            }
        } else {
            
        }

        const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

        if (networkData) {

            // Load NFT contract
            const abi = Token.abi
            const address = networkData.address
            const contract = new web3.eth.Contract(abi, address)

            // Load auction contract
            const abi_auction = TokenAuction.abi
            const address_auction = networkAuctionData.address
            const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

            // Get total supply
            const totalSupply = await contract.methods.totalSupply().call()
            const totalOwned = await contract.methods.balanceOf(this.props.match.params.id).call()

            this.setState({contract: contract});
            this.setState({contract_auction: contract_auction});
            this.setState({totalSupply: totalSupply})
            this.setState({totalOwned: totalOwned})
            
            if (totalOwned > 0) {
                for(var i = 0; i <= totalSupply-1; i++) {

                    let tokenId = ''
                    await contract.methods.tokenOfOwnerByIndex(this.props.match.params.id, i).call().then((value) => { 
                        tokenId = value
                    }); 

                    const _NFT = await contract.methods.tokenIdToNFT(tokenId).call()
                    const tokenOwner = await contract.methods.ownerOf(tokenId).call() 
                    const token = await contract.methods.tokens(tokenId).call()
                    const tokenURI = await contract.methods.tokenURI(tokenId).call()
                    const auctionDate = await contract_auction.methods.getTimeleft(tokenId).call()
                    const auctionPrice = await contract_auction.methods.tokenIdToAuction(tokenId).call()
                    const auction = await contract_auction.methods.tokenIdToAuction(tokenId).call()
                    const auctionStarted = await contract_auction.methods.started(tokenId).call()

                    const web3_utils = require('web3-utils');

                    let tokenPriceEth = ''
                    if (_NFT.price) {
                        tokenPriceEth = web3_utils.fromWei(_NFT.price.toString(), 'ether')
                    } else {
                        tokenPriceEth = 0
                    }

                    // Get auction time
                    let utcSeconds = 1;
                    if (auctionDate != 0 && auctionPrice.bidCount > 0) {
                        utcSeconds = auctionDate.toString();
                        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                        d.setUTCSeconds(utcSeconds);
                    }

                    const auctionTimer = ((utcSeconds) - (Date.now()/1000))*1000;

                    this.setState({
                        colors: [...this.state.colors, token ],
                        owners: [...this.state.owners, tokenOwner]
                    })

                    // Retrieve IPFS data
                    this.RetrieveIPFS(token, tokenOwner, tokenId, tokenPriceEth, tokenURI, _NFT.creator, auctionTimer, auction.currentBidAmount.toString(), auction.bidCount, auctionStarted);

                    this.fetchCurrencyData();
                }
            }

        } else {
            window.alert("Smart contract not deployed to detected network")
        }
    }

    fetchCurrencyData = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        .then(response => {
            console.log(response.data.USD)
            this.setState({ currentUsdValue: response.data.USD })

        })
        .catch(err => console.log(err))
    }

    ETHtoUSD = (eth) => {
        return ((this.state.currentUsdValue * eth).toLocaleString(undefined, {maximumFractionDigits:2}))
    }

    Auction(key, date, currentBid, bidCount, fixedPrice, auctionStarted) {

        const web3_utils = require('web3-utils');

        if (auctionStarted) {
        return <div>
            <h2 className="current-bid"></h2>
            <Countdown renderer={props => 
                <div class="auction-timer">

                    <div className="auction-section"><h5>Bids </h5>
                    <h3>{bidCount}</h3>
                    </div>

                    <div className="auction-section">
                    <h5>Current bid </h5>
                    <h3><i class="fab fa-ethereum"></i> {web3_utils.fromWei(currentBid, 'ether')} ETH</h3>
                    </div>

                    { auctionStarted && bidCount > 0 &&
                    <div className="auction-section"><h5>Ending in </h5>
                    <h3><i class="fas fa-stopwatch"></i> {props.hours}h {props.minutes}m {props.seconds}s</h3>
                    </div>
                }
                   
                </div>} 

                key={key} ref={this.state.countdownRef} autoStart={true} date={Date.now() + (parseInt(date))}>
                    
            </Countdown>
                 
        </div>
        }
    }

    FixedPrice(key, fixedPrice, auctionStarted) {

        if (!auctionStarted && fixedPrice > 0) {
            return <div>
                <h2 className="current-bid"></h2>
                <div class="auction-timer">
                    <div className="auction-section"><h5>Fixed price </h5>
                    <h3><i class="fab fa-ethereum"></i> {fixedPrice} ETH <span className="usd">(${this.ETHtoUSD(fixedPrice)})</span></h3>
                    </div>
                   
                </div>
            </div>
        }
    }

    MetaData(key, owner, creator) {

        return <div key={key}>
            <h2 className="current-bid"></h2>

            <div class="auction-timer">
                <div className="auction-section"><h5>Owner</h5>
                <h4 class="address"><i class="fas fa-user-tag"></i> <a href={owner}>{owner}</a></h4>
                </div>

                <div className="auction-section"><h5>Creator</h5>
                <h4 class="address"><i class="fas fa-user-edit"></i> <a href={creator}>{creator}</a></h4>
                </div>
            </div>
        </div>
    }

   async RetrieveIPFS(token, owner, id, price, uri, creator, auctionDate, currentBid, bidCount, auctionStarted) {
        fetch("https://moonbear.mypinata.cloud/ipfs/"+token)
          .then(response => response.json())
          .then((jsonData) => {

            // jsonData is parsed json object received from url
            var imageHash = jsonData.image.replace('ipfs://','');

            this.setState({
                tokens: [ ...this.state.tokens, 
                { 
                    owner: owner, 
                    color: token, 
                    id: id, 
                    price: price, 
                    uri: uri,
                    image: imageHash,
                    name: jsonData.name,
                    description: jsonData.description,
                    creator: creator,
                    date: auctionDate,
                    currentBid: currentBid,
                    bidCount: bidCount,
                    auctionStarted: auctionStarted,
                    fileType: jsonData.fileType
                }]
            })
        }).catch((error) => {
            // error
        })
    }

    Badge(date, auctionStarted, bidCount, fixedPrice) {

        if ((date <= 0 && auctionStarted && bidCount > 0)) {
            return <div><span class="badge bg-outline-faded">Auction Complete</span></div>
        }

        if (!auctionStarted && fixedPrice > 0) {
            return <div><span class="badge bg-outline-green">Fixed price</span></div>
        }

        if ((date > 0 && auctionStarted) || (bidCount == 0 && auctionStarted)) {
            return <div><span class="badge bg-outline">Auction</span></div>
        }

        if (!auctionStarted && fixedPrice == 0) {
            return <div><span class="badge bg-outline-faded">Not for sale</span></div>
        }

    }

    CollectionIsEmpty() {
        if (this.state.totalOwned == 0 && this.state.account != this.props.match.params.id) {
            return (
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">

                        <h2>This user's collection is empty :( {this.state.totalOwned}</h2>
                    </div>
                    </div>
                )
        } else if (this.state.totalOwned == 0) {

            return (
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center">

                        
                        <h2>Your collection is empty.</h2> 
                        <p>Mint your own work or visit our marketplace to start collecting exciting art to add to your collection!</p>
                        <div class="buttons">
                            <a href="/" className="btn btn-white btn-lg">Browse marketplace</a>
                            <a href="/" className="btn btn-primary btn-lg"><i class="fas fa-plus"></i> Mint your own art</a>
                        </div>
                         
                    </div>
                </div>
                )

        }
    }

    render() {
        return (
            <div className="wrapper">
                <div className="main">

                    <section class="py-5 text-center container hero">
                        <div class="row py-lg">
                          <div class="col-lg-12 col-md-12 mx-auto">
                            <h1 class="fw-light">Collection</h1>
  
                            <a href={"/"+this.props.match.params.id} className="btn btn-light btn-lg"><i class="fas fa-wallet"></i> 
                                        {this.props.match.params.id}
                                    </a>
                          </div>
                        </div>
                      </section>

                    <div className="content">
                        <div className="container p-0">

                            {this.CollectionIsEmpty()}    
                            <div data-masonry='{"percentPosition": true }'>

                            
                            <Masonry
                            breakpointCols={this.state.breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column col-md-4">
                            {
                                this.state.tokens.map((token, key) => {
                                    return (
                                    <div className="card" key={key}>
                                        <a href={"/item/"+token.id}>
                                            <NFTImage tokenImage={token.image} tokenFileType={token.fileType}/>
                                        </a>
                                                    
                                        <div className="card-body">
                                            <h2 className="card-title"><a href={"/item/"+token.id}>{token.name}</a></h2>
                                            <p className="card-description">{token.description}</p>
                                            {this.Badge(token.date, token.auctionStarted, token.bidCount, token.price)}
                                            <hr/>
                                            {this.Auction(key, token.date, token.currentBid, token.bidCount,token.price,token.auctionStarted)}
                                            {this.FixedPrice(key, token.price, token.auctionStarted)}
                                            {this.MetaData(key, token.owner, token.creator)}
                                            <hr/>
                                        </div>
                                    </div>
                                        )
                                })
                            }
                            </Masonry>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;