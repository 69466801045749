// src/Header.js

import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';

class Header extends Component {

	async componentWillMount() {
		await this.loadWeb3()


	}

	async loadWeb3() {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum)
			await window.ethereum.enable()
			await this.loadBlockchaindata()
			this.setState({metamask: "true"})
		} else if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider)
			await this.loadBlockchaindata()
			this.setState({metamask: "true"})
		} else {
			this.setState({metamask: "false"})
		}
	}

	async loadBlockchaindata() {
		const web3 = window.web3
		// load account
		const accounts = await web3.eth.getAccounts()
		const balance = await web3.eth.getBalance(accounts[0])
		this.setState({
			account: accounts[0],
			balance: balance
		});
	}

	constructor(props) {
		super(props);
		this.state = { 
			account: '',
			metamask: ''
		}
  	}

  	SignIn() {
  		if (this.state.metamask == "true") {
  			return <a href={"/"+this.state.account} className="btn btn-light btn-lg"><i className="fas fa-wallet"></i> <span className="header-address address">
										{this.state.account}
									</span></a>
								} else {
									return <a href="https://metamask.app.link/dapp/nft.moonbear.com" className="btn btn-light btn-lg"><i className="fas fa-wallet"></i> <span className="header-address address">
										Sign in
									</span></a>
								}
  	}

	render() {
		return (
			<div>
				<nav className="navbar navbar-expand navbar-light navbar-bg fixed-top">

						<a className="sidebar-toggle" href="/">
							<img alt="" src="/logo-dark.png"/>
							<span className="logo-text">MOONBEAR</span>
				        </a>
						
						<div className="navbar-collapse collapse">
							<ul className="navbar-nav navbar-align">
	

								<li>
									<div className="btn-group">
										{this.SignIn()}
										<a href="/mint" className="btn btn-primary btn-lg"><i className="fas fa-plus"></i> Create</a>
									</div>
								</li>
							</ul>
						</div>
				</nav>
			</div>
		)

	}
}

export default Header