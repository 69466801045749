
import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import Token  from '../abis/Token.json';
import TokenAuction  from '../abis/TokenAuction.json';
import { Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import dateFormat from 'dateformat';
import NFTImage from './NFTImage';
import MetaMaskOnboarding from '@metamask/onboarding'
import axios from 'axios';
import { Video } from 'react-video-stream'
import ContentLoader from "react-content-loader"

class Single extends Component {

    async componentWillMount() {

        // Set auction date to 0 for now
        this.setState({ date: "0" })

        await this.loadWeb3()
        await this.loadBlockchaindata()
    }

    //This will start the onboarding proccess
    onClickInstall = () => {
        const forwarderOrigin = 'http://localhost:3000';
        const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
          // onboardButton.innerText = 'Onboarding in progress';
          // onboardButton.disabled = true;
          //On this object we have startOnboarding which will start the onboarding process for our end user
          onboarding.startOnboarding();
    };

   async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            this.setState({metamask: "true"})
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            this.setState({metamask: "true"})
        } else {
            window.web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/723ee4d221864874b81cd8c6f7ae6b5f'))
            // window.web3 = new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:7545'))
             this.setState({metamask: "false"})
        }
    }



    timeDifference(current, previous) {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' seconds ago';   
        }

        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' minutes ago';   
        }

        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' hours ago';   
        }

        else if (elapsed < msPerMonth) {
            return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
        }

        else if (elapsed < msPerYear) {
            return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
        }

        else {
            return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
        }
    }


    fetchCurrencyData = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        .then(response => {
            console.log(response.data.USD)
            this.setState({ currentUsdValue: response.data.USD })

        })
        .catch(err => console.log(err))
    }

    ETHtoUSD = (eth) => {
        return ((this.state.currentUsdValue * eth).toLocaleString(undefined, {maximumFractionDigits:2}))
    }

    async loadBlockchaindata() {
        const web3 = window.web3


        if (this.state.metamask == "true") {
            //load account
            const accounts = await web3.eth.getAccounts()
            if (accounts.length > 0) {
                const balance = await web3.eth.getBalance(accounts[0])
                this.setState({
                    account: accounts[0],
                    balance: balance
                });
            }
        } else {
            
        }

        const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

        if (networkData) {
            const abi = Token.abi
            const address = networkData.address
            const contract = new web3.eth.Contract(abi, address)

            const abi_auction = TokenAuction.abi
            const address_auction = networkAuctionData.address
            const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

            this.setState({
                contract: contract,
                contract_auction: contract_auction
            });

            const tokenOwner = await contract.methods.ownerOf(this.props.match.params.id).call()  
            const tokenColor = await contract.methods.tokens(this.props.match.params.id).call()
            const tokenURI = await contract.methods.tokenURI(this.props.match.params.id).call()
            const _NFT = await contract.methods.tokenIdToNFT(this.props.match.params.id).call()
            const web3_utils = require('web3-utils');
            const tokenPriceEth = web3_utils.fromWei(_NFT.price.toString(), 'ether')
            const auctionPrice = await contract_auction.methods.tokenIdToAuction(this.props.match.params.id).call()
            const auctionActive = await contract_auction.methods.getStatus(this.props.match.params.id).call()
            const auctionStarted = await contract_auction.methods.started(this.props.match.params.id).call()
            const bidHistory = await contract_auction.methods.getBidHistory(this.props.match.params.id).call()

            // Compare this time with server time
            const auctionDate = await contract_auction.methods.getTimeleft(this.props.match.params.id).call()

            // Date minted
            let utcSeconds = _NFT.dateMinted;
            var dateMinted = new Date(0); // The 0 there is the key, which sets the date to the epoch
           
            dateMinted.setUTCSeconds(utcSeconds);

            for(var i = 0; i <= bidHistory.length - 1; i++) {

                var bidTime = new Date(0); // The 0 there is the key, which sets the date to the epoch
                bidTime.setUTCSeconds(bidHistory[i].timestamp);

                this.setState({
                    bids: [ ...this.state.bids, 
                    { 
                        bidder: bidHistory[i].bidder,
                        amount: bidHistory[i].amount.toString(),
                        timestamp: bidTime
                    }]
                })
            }


            utcSeconds = 1;
            if (auctionDate != 0 && auctionPrice.bidCount > 0) {
                utcSeconds = auctionDate.toString();
                var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                d.setUTCSeconds(utcSeconds);

                this.setState({date: ((utcSeconds) - (Date.now()/1000))*1000});
            }

            const now = Math.round(Date.now() / 1000)
            var dNow = new Date(0); // The 0 there is the key, which sets the date to the epoch
            dNow.setUTCSeconds(now);

            this.setState({
                color: tokenColor,
                owner: tokenOwner,
                price: tokenPriceEth,
                creator: _NFT.creator.toString(),
                royalty: _NFT.royalty,
                currentBid: auctionPrice.currentBidAmount.toString(),
                startingBid: auctionPrice.startingBid.toString(),
                auctionActive: auctionActive,
                currentBidOwner: auctionPrice.currentBidOwner.toString(),
                auctionDuration: auctionPrice.duration.toString(),
                auctionStarted: auctionStarted,
                bidCount: auctionPrice.bidCount,
                dateMinted: dateMinted.toString(),
                utcSeconds: auctionDate.toString()
            })

            console.log(auctionPrice.bidCount)

            if (this.state.auctionStarted && this.state.bidCount > 0) {
                this.state.countdownRef.current.start()
            }

            this.RetrieveIPFS(tokenColor)

            this.fetchCurrencyData()

        } else {
            window.alert("Smart contract not deployed to detected network")
        }
    }

    componentDidUpdate(prevProps, prevState) {

            console.log(prevState)
        
    }

    buy= (id, price) => {

        if (this.state.metamask == "false") {
            window.location.href = 'https://nft.moonbear.com/signin'; 
        } else {
            const web3_utils = require('web3-utils');
            const priceWithCommision = (parseInt(price)+1).toString()

            web3_utils.toWei(price)
            const _this = this

            this.state.contract.methods.buy(id).send({from: this.state.account, value: web3_utils.toWei(price), gas: 220000})
            .once('receipt', function(){
                _this.setState({
                    owner: _this.state.account,
                    price: 0
                })
            })
        }
    }

    transfer= (id, price) => {

        const web3_utils = require('web3-utils');
        const priceWithCommision = (parseInt(price)+1).toString()

        web3_utils.toWei(price)

        this.state.contract.methods.approveToken(this.state.contract_auction._address, id).send({from: this.state.account})
        .once('receipt', (receipt) => {
        })
    }

    auction= (id, price) => {

        const web3_utils = require('web3-utils');

        this.state.contract.methods.setSellType(2, id, this.state.account).send({from: this.state.account})
        .once('receipt', (receipt) => {
        })
    }

    sellType= (id, price, sellType) => {

        const web3_utils = require('web3-utils');
        
        this.state.contract.methods.setSellType(id, sellType, this.state.account).send({from: this.state.account})
        .once('receipt', (receipt) => {
        })
    }

    bid= (id, price) => {
        const web3_utils = require('web3-utils');
        const _this = this;

        this.state.contract_auction.methods.bid(id, web3_utils.toWei(price)).send({from: this.state.account, value: web3_utils.toWei(price)})
        .once('receipt', function(){

            _this.setState({
                bidCount: _this.state.bidCount + 1,
                auctionStarted: true,
                currentBid: web3_utils.toWei(_this.state.bidPrice).toString(),
                bids: [ ..._this.state.bids, 
                { 
                    bidder: _this.state.account,
                    amount: web3_utils.toWei(price).toString(),
                    timestamp: Date.now()
                }]
            })

            if (_this.state.date == 0) {
                _this.setState({
                    date: 86400*1000
                })
                _this.state.countdownRef.current.start()
            }

            _this.setState({
                bidSubmitted: price
            })

            console.log(_this.state.bidCount)
         })
    }

    claim= (id, price) => {
        const web3_utils = require('web3-utils');
        const _this = this;
        this.state.contract.methods.claimAsset(id, this.state.contract_auction._address).send({from: this.state.account})
        .once('receipt', function(){
            _this.setState({
                currentBidOwner: "",
                auctionStarted: false,
                price: 0,
                currentBid: 0,
                bidCount: 0,
                bidHistory: [],
                owner: _this.state.account
            })
         })
    }

    allowBuy = (id, price) => {

        const web3_utils = require('web3-utils');
        const _this = this;

        web3_utils.toWei(this.state.sellPrice)

        this.state.contract.methods.allowBuy(id, web3_utils.toWei(this.state.sellPrice.toString())).send({from: this.state.account})
        .once('receipt', function(){
            _this.setState({
                price: _this.state.sellPrice
            })
         })
    }

    startAuction = (id, price) => {
        const web3_utils = require('web3-utils');
        const thisState = this;
        //this.state.contract.methods.setSellType(2, id, this.state.account).send({from: this.state.account})

        this.state.contract_auction.methods.createAuction(id, this.state.contract._address, 0, 0, 86000, 300).send({from: this.state.account})
        .once('receipt', function(){
            thisState.setState({
                auctionStarted: true
            })
         })
    }

    cancelAuction = (id, price) => {
        const web3_utils = require('web3-utils');
        const thisState = this;

        this.state.contract_auction.methods.cancel(id).send({from: this.state.account})
        .once('receipt', function(){
            thisState.setState({
                auctionStarted: false
            })
         })
    }

    disallowBuy= (id) => {

        const thisState = this;
        this.state.contract.methods.disallowBuy(id).send({from: this.state.account})
        .once('receipt', function(){
            thisState.setState({
                price: 0
            })
         })
    }

    handleChange(i, e) {
        this.setState({
          to: e.target.value
        });

        console.log(e.target.value)
     }

    getPrice(i, e) {
        this.setState({
          sellPrice: i.target.value
        });
    }

    getBidPrice(i) {
        this.setState({
          bidPrice: i.target.value
        });
    }

    getAuctionPrice(i, e) {
        this.setState({
          auctionPrice: i.target.value
        });
    }

    getRoyalty(i, e) {
        this.setState({
          setRoyalty: i.target.value
        });

        console.log(i.target.value)
    }

    constructor(props) {
        super(props);
        this.state = { 
            account: '',
            balance: '',
            contract: null,
            contract_auction: null,
            totalSupply: 0,
            tokens: [],
            to: '',
            sellPrice: '',
            auctionPrice: "",
            bidPrice: "",
            currentBid: "",
            price: '',
            owner: '',
            setRoyalty: '',
            royalty: '',
            metaImage: '',
            metaName: '',
            metaDescription: '',
            metaFileType: '',
            date: '',
            countdownRef: React.createRef(),
            startingBid: '',
            auctionActive: '',
            currentBidOwner: '',
            auctionDuration: '',
            auctionStarted: '',
            bids: [],
            dateMinted: '',
            bidSubmitted: '',
            metamask: '',
            utcSeconds: '',
            currentUsdValue: '',
            nftLoaded: ''
        }

        this.allowBuy = this.allowBuy.bind(this);
        this.getBidPrice = this.getBidPrice.bind(this);
        this.myRef = React.createRef();
    }

    SellButton() {
        if (this.state.sellPrice > 0) {
            return <button class="btn btn-primary btn-lg" type="submit">Sell fixed price</button>
        } else {
            return <button class="btn btn-primary btn-lg" type="submit" disabled>Sell fixed price</button>
        }
    }

    SellForm(owner, id, price) {
        if (this.state.contract_auction != null) {
            if (this.state.account == owner && price == 0 && !this.state.auctionStarted) {
                return <div class="sell-card-section">
                    <div class="eth-form">
                        <input onChange={this.getPrice.bind(this)} type='text' className='form-control mb-1 bid-input' placeholder='0'/>
                        <div class="input-eth"><i class="fab fa-ethereum"></i> ETH</div>
                    </div>
                    <p></p>
                    
                   <form onSubmit={(event) => {
                        event.preventDefault()
                        this.allowBuy(id, price)
                    }}>

                    <div class="d-grid gap-2">
                        {this.SellButton()}
                    </div>
               
                    </form>
                    <div class="or-spacing"></div>
                    <div class="or">OR</div>
                </div>
            }
        }
      
    }

    AuctionForm(owner, id, price) {
        if (this.state.contract_auction != null) {
            if (this.state.account == owner && price == 0 && !this.state.auctionStarted) {
                return <div class="sell-card-section">
                <form onSubmit={(event) => {
                    event.preventDefault()
                    this.startAuction(id, price)
                }}>
                <div class="d-grid gap-2">
                <input type="submit" className="btn btn-block btn-primary btn-lg" value="Start auction"/>
                </div>
                <hr />
                </form>
            </div>
            
                
            }
        }
      
    }

    BuyButton(owner, id, price) {

        if (this.state.account != owner && price > 0) {
            return <div class="sell-card-section">
            <form onSubmit={(event) => {
                event.preventDefault()
                this.buy(id, price)
            }}>
            <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Buy"/>
            </form> 
            <hr/>
            </div>
            
        }
    }

    RemoveFromSaleButton(owner, id, price) {
        if (this.state.account == owner && price > 0) {
            return <div class="sell-card-section">

                <form onSubmit={(event) => {
                    event.preventDefault()
                    this.disallowBuy(id)
                }}>

                <div class="d-grid gap-2">
                    <input type="submit" className="btn btn-block btn-primary btn-lg" value="Remove from sale"/>
                </div>
            </form> 
            <hr/>
            </div>
        } else {

        }
    }

    CountdownComplete = () => { 
        this.setState({
            date: 0
        })
    }

    Auction() {
        const web3_utils = require('web3-utils');

        if (this.state.contract_auction != null) {
            if (this.state.auctionStarted && this.state.bidCount > 0) {
                return <div class="sell-card-section">
                    <h2 className="current-bid">{web3_utils.fromWei(this.state.currentBid.toString(), 'ether')} 
                        <span className="currency"><i class="fab fa-ethereum"></i></span> 
                        {(this.state.date > 0 && this.state.bidCount > 0) || (this.state.bidCount == 0) ? <span class="badge bg-outline">Auction</span> : <span class="badge bg-outline-faded">Auction Complete</span>}
                    </h2>
                    <span className="usd-bottom">(${this.ETHtoUSD(web3_utils.fromWei(this.state.currentBid.toString(), 'ether'))})</span>
                    <Countdown renderer={props => 
                        <div class="auction-timer">

                            <div class="countdown-timer">
                                <span className="countdown-title">{props.hours}</span> <span class="bold">Hours</span>
                            </div>  

                            <div class="countdown-timer">{props.minutes} 
                                <span class="bold">Minutes</span>
                            </div> 

                            <div class="countdown-timer">{props.seconds} 
                                <span class="bold">Seconds</span>
                            </div>  

                            <div class="countdown-timer">{this.state.bidCount} 
                                <span class="bold">Bids</span>
                            </div>  
                        </div>
                    } key={this.state.date} onComplete={this.CountdownComplete} ref={this.state.countdownRef} autoStart={false} date={Date.now() + (parseInt(((this.state.utcSeconds) - (Date.now()/1000))*1000))}>
            }
                    </Countdown>
                    <p></p>
                </div>
            } else if (this.state.auctionStarted && this.state.bidCount == 0) {
            return <div class="sell-card-section">
                <h2 className="current-bid">{web3_utils.fromWei(this.state.currentBid.toString(), 'ether')} 
                    <span className="currency"><i class="fab fa-ethereum"></i></span> 
                    {(this.state.date > 0 && this.state.bidCount > 0) || (this.state.bidCount == 0) ? <span class="badge bg-outline">Auction</span> : <span class="badge bg-outline-faded">Auction Complete</span>}
                </h2>

                <h4>Timer will begin after first bid.</h4>
                     <p></p>
                </div>
            }
        }

    }

    AuctionBid(owner, id, price) {
         const web3_utils = require('web3-utils');
         const currentBid = web3_utils.fromWei(this.state.currentBid.toString(), 'ether')

        if (owner != this.state.account && (this.state.date > 0 || (this.state.auctionStarted && this.state.bidCount == 0))) {
        return  <div className="sell-card-section">
            <form onSubmit={(event) => {
                event.preventDefault()
                this.bid(id, this.state.bidPrice)
                }}>
                <div className="eth-form">
                <input onChange={this.getBidPrice} type='text' className='form-control mb-1 bid-input' placeholder='0'/>
                <div class="input-eth"><i class="fab fa-ethereum"></i> ETH </div>
                </div>
                <p>Bids placed in the last 10 minutes will extend the auction by another 10 minutes.</p>


                {this.PlaceBidButton()}
            </form>
            {this.GetMetaMask()}
            <hr/>
            </div>
        }
    
    }

    Price() {

        const web3_utils = require('web3-utils');
    
        if (!this.state.auctionStarted && this.state.price > 0) {
            return <div class="sell-card-section">
                <h2 className="current-bid">
                    {this.state.price.toString()} 
                    <span className="currency"><i class="fab fa-ethereum"></i></span> <span class="badge bg-outline-green">Fixed price</span>
                </h2>
                <span className="usd-bottom">(${this.ETHtoUSD(this.state.price)})</span>
                <p></p>
            </div>
        }
    }

    MetaData() {

        const web3_utils = require('web3-utils');
    
        return <div class="sell-card-section">
        
            <h2 className="current-bid">{ this.state.metaName }</h2>
            <p className="large">{ this.state.metaDescription }</p>
            <div class="badges">
            {(!this.state.auctionStarted && this.state.price == 0) ? <span className="badge bg-outline-faded">Not for sale</span> : ""} {(this.state.account == this.state.owner) ? <span class="badge bg-outline-blue">You are owner</span> : ""}
            </div>
            
            <hr />
        </div>
        
    }

    PlaceBidButton() {

        const web3_utils = require('web3-utils');
        const currentBid = web3_utils.fromWei(this.state.currentBid.toString(), 'ether')

        if (this.state.metamask != "" && this.state.metamask == "true") {
            if (parseFloat(this.state.bidPrice) < parseFloat(currentBid)+parseFloat(0.001) || this.state.bidPrice == "") {
                return <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value={"bid "+(parseFloat(currentBid)+parseFloat(0.001)).toString()+" or more"} disabled/>
            } 

            if (parseFloat(this.state.bidPrice) >= parseFloat(currentBid)+parseFloat(0.001)) {
                return <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value={this.state.bidPrice.toString()+ " ETH - Place bid"}/>
            } 
        }

    }

    GetMetaMask() {
        if (this.state.metamask == "false") {
            return <div class="get-metamask">
                <img src="/metamask.png" width="100px" height="auto"/>
                <span>Download and connect to metamask wallet to start buying and selling.</span>
                <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Connect wallet" onClick={this.onClickInstall}/>
            </div>
        }
    }

    CancelAuction(owner, id, price) {
        if (this.state.account == owner && this.state.auctionStarted && this.state.bidCount == 0) {
            return <div className="sell-card-section"><form onSubmit={(event) => {
                event.preventDefault()
                this.cancelAuction(id)
            }}>

            <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Cancel auction"/>
            </form> 

            <hr/>
            </div>
        } else if (this.state.account == owner && this.state.auctionStarted && this.state.bidCount > 0)


            if (this.state.auctionActive == true) {
            return <div className="sell-card-section"><form onSubmit={(event) => {
                event.preventDefault()
                this.cancelAuction(id)

            }}>

            <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Cancel auction" disabled/>

            </form> 
            <div className="space-20"></div>
            <p>Auction has now started and cannot be cancelled</p>
        
            <hr/>
            
            </div>
        }
    }

    ClaimAsset(owner, id, price) {
        const web3_utils = require('web3-utils');

        if (this.state.contract_auction != null) {
            if (this.state.currentBidOwner == this.state.account && this.state.date < 1) {
                return  <div className="sell-card-section">
                <form onSubmit={(event) => {
                    event.preventDefault()
                    this.claim(id, this.state.currentBid)
                    }}>
                    <p>You won the auction! Click the button below to transfer into your collection</p>
                    <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Claim asset"/>

        
                </form>
            <hr/>
            </div>
            }
        }
    }

    BidsHistory() {
        const web3_utils = require('web3-utils');

    

        if (this.state.bidCount > 0) {
            return (
            <div class="sell-card-section">
                <h4>Auction bid history:</h4>
                <p></p>
                                {
                [...this.state.bids].reverse().map((token, key) => {
                    return (
                        <div class="bids-history">
                            <div class={key == 0 ? 'bid highest-bid' : 'bid' }>

                            <span className="bidder address">
                                <i class="fas fa-user"></i> <a href={"/"+token.bidder}>{token.bidder}</a>
                            </span> 

                            <span className="amount">{web3_utils.fromWei(token.amount, 'ether')} <i class="fab fa-ethereum"></i></span>

                            <span className="time">{this.timeDifference(Date.now(), token.timestamp)}</span>


                            </div>  
                        </div> 
                    )
                })
            }
            <hr/>
            </div>
            )
        }

    }

    FooterData(owner, creator) {
        return <div className="sell-card-section">
            <div class="auction-timer">

                    <div className="auction-section"><h5>Owner</h5>
                    <h4 class="address"><i className="fas fa-user-tag"></i> <a href={"/"+owner}>{this.state.owner}</a></h4>
                    </div>

                    <div className="auction-section"><h5>Creator</h5>
                    <h4 class="address"><i className="fas fa-user-edit"></i> <a href={"/"+creator}>{this.state.creator}</a></h4>
                    </div>
                   
                </div>

                <div class="auction-timer">

                    <div className="auction-section"><h5>Creator Royalty</h5>
                        <h4 class="address">{this.state.royalty}%</h4>
                    </div>

                    <div className="auction-section"><h5>Date minted</h5>
                        <h6>{dateFormat(this.state.dateMinted)}</h6>
                    </div>
                </div>

            </div>
    }

   async RetrieveIPFS(hash) {

        fetch("https://moonbear.mypinata.cloud/ipfs/"+hash)
          .then(response => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url

            var imageHash = jsonData.image.replace('ipfs://','');

            this.setState({
              metaImage: imageHash,
              metaName: jsonData.name,
              metaDescription: jsonData.description,
              metaFileType: jsonData.fileType
            });

          })
          .catch((error) => {
            // handle your errors here
            console.error(error)
          })
    }

    alerts() {
        if (this.state.bidSubmitted != "") {
        return  <div class="sell-card-section">
            <div class="alert alert-success alert-dismissible" role="alert">
                                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={this.clearAlerts}></button>
                                            <div class="alert-icon">
                                                <i class="far fa-fw fa-bell"></i>
                                            </div>
                                            <div class="alert-message">
                                                {this.state.bidSubmitted} ETH - Bid submitted!
                                            </div>
                                        </div>
        </div>
    }
    }

    clearAlerts = () => { 
        this.setState({
            bidSubmitted: 0
        })
    }

    media() {
        if (this.myRef.value == null && this.state.metaImage != "") {
            return <NFTImage ref={this.myRef} tokenImage={this.state.metaImage} onLoad={() => this.setState({nftLoaded: true})} tokenFileType={this.state.metaFileType}/>
        } 
    }



    render() {
        return (
        <div className="main">
            <div class="content">
            <div className="container pt-5">
                <Row>
                
                    <div className="col-md-8">
                        <div className="item">

                            {this.media()}

                        </div>
                    </div>
          
                    <div className="col-md-4">
                        <div className="single-side" style={this.state.metaName != "" ? {} : {display: "none"}}>    

                            {this.alerts()}    
                            {this.Auction()}
                            {this.Price()}
                            {this.CancelAuction(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.BuyButton(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.ClaimAsset(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.AuctionBid(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.MetaData()}
                            {this.SellForm(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.AuctionForm(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.BidsHistory()}
                            {this.RemoveFromSaleButton(this.state.owner, this.props.match.params.id, this.state.price)}
                            {this.FooterData(this.state.owner, this.state.creator)}
                        </div>

                        <div class="singlge-side sell-card-section preloader" style={this.state.metaName != "" ? {display: "none"} : {}}>
                            <ContentLoader 
                            speed={2}
                            width={400}
                            height={250}
                            viewBox="0 0 400 250"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ccc"

                          >
                             <rect x="0" y="60" rx="3" ry="10" width="410" height="15" /> 
                            <rect x="0" y="90" rx="3" ry="20" width="380" height="15" /> 
                            <rect x="0" y="120" rx="3" ry="10" width="178" height="15" /> 
                            <rect x="0" y="150" rx="3" ry="10" width="410" height="15" /> 
                            <rect x="0" y="180" rx="3" ry="20" width="380" height="15" /> 
                            <rect x="0" y="210" rx="3" ry="10" width="178" height="15" /> 
                          </ContentLoader>
                        </div>
                    </div>
                                
                </Row>
            </div>
            </div>
        </div>
        );
    }
}

export default Single;