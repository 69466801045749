import React, { Component } from 'react';
import './css/light.css';
import './App.css';

import createHistory from 'history/createBrowserHistory'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home, Profile, Single, Mint, Faq, SignIn, usePageTracking } from ".";
import Header from './Header';
import Footer from './Footer';

import ReactGA from 'react-ga';


class App extends Component {

	route() {
		
		const history = createHistory()
		ReactGA.initialize('G-S9RMTEL2CQ');
		history.listen((location, action) => {
		  ReactGA.set({ page: location.pathname });
		  ReactGA.pageview(location.pathname);
		});

		return (<Router history={history}>
	  		

	        <Switch>
	          <Route path="/" exact component={() => <Home />} />
	          {/* <Route path="/about" exact component={() => <About />} /> */}
	          <Route path="/mint" exact component={() => <Mint />} />
	          <Route path="/signin" exact component={() => <SignIn />} />
	          <Route path="/faq" exact component={() => <Faq />} />
	          <Route path="/:id" exact component={(props) => <Profile {...props}/>}/> 
	          <Route path="/item/:id" exact component={(props) => <Single {...props}/>}/> 
	        </Switch>

	      </Router>)
	}


	render() {
	  return (

	  

	    <div className="App">

	    	<Header />
	      	
	      	{this.route()}

	      <Footer />
	    </div>
	  );
	}
}

export default App;
