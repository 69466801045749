import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import './App.css';
import NFTImage from './NFTImage';
import Home from './Home';
import Countdown from 'react-countdown';
import dateFormat from 'dateformat';
import Token  from '../abis/Token.json';
import TokenAuction  from '../abis/TokenAuction.json';
import axios from 'axios';

class WideFeature extends Component {

	constructor(props) {
        super(props);
        this.state = { 
            account: '',
            balance: '',
            contract: null,
            contract_auction: null,
            totalSupply: 0,
            tokens: [],
            to: '',
            sellPrice: '',
            auctionPrice: "",
            bidPrice: "",
            currentBid: "",
            price: '',
            owner: '',
            setRoyalty: '',
            royalty: '',
            metaImage: '',
            metaName: '',
            metaDescription: '',
            date: '',
            countdownRef: React.createRef(),
            startingBid: '',
            auctionActive: '',
            currentBidOwner: '',
            auctionDuration: '',
            auctionStarted: '',
            bids: [],
            dateMinted: '',
            bidSubmitted: '',
            metamask: '',
            utcSeconds: '',
            currentUsdValue: ''
        }

        //this.allowBuy = this.allowBuy.bind(this);
        this.getBidPrice = this.getBidPrice.bind(this);
    }

  	getBidPrice(i) {
        this.setState({
          bidPrice: i.target.value
        });
    }

  	async componentWillMount() {
  		this.loadBlockchaindata() 
         this.fetchCurrencyData()
  	}

  	async loadBlockchaindata() {
        const web3 = window.web3

        //load account
        // const accounts = await web3.eth.getAccounts()
        // const balance = await web3.eth.getBalance(accounts[0])
        // this.setState({
        //     account: accounts[0],
        //     balance: balance
        // });

        const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

        if (networkData) {
            const abi = Token.abi
            const address = networkData.address
            const contract = new web3.eth.Contract(abi, address)

            const abi_auction = TokenAuction.abi
            const address_auction = networkAuctionData.address
            const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

            this.setState({
                contract: contract,
                contract_auction: contract_auction
            });

            const tokenOwner = await contract.methods.ownerOf(this.props.id).call()  
            const tokenColor = await contract.methods.tokens(this.props.id).call()
            const tokenURI = await contract.methods.tokenURI(this.props.id).call()
            const _NFT = await contract.methods.tokenIdToNFT(this.props.id).call()
            const web3_utils = require('web3-utils');
            const tokenPriceEth = web3_utils.fromWei(_NFT.price.toString(), 'ether')
            const auctionPrice = await contract_auction.methods.tokenIdToAuction(this.props.id).call()
            const auctionActive = await contract_auction.methods.getStatus(this.props.id).call()
            const auctionStarted = await contract_auction.methods.started(this.props.id).call()
            const bidHistory = await contract_auction.methods.getBidHistory(this.props.id).call()

            // Compare this time with server time
            const auctionDate = await contract_auction.methods.getTimeleft(this.props.id).call()

            // Date minted
            let utcSeconds = _NFT.dateMinted;
            var dateMinted = new Date(0); // The 0 there is the key, which sets the date to the epoch
           
            dateMinted.setUTCSeconds(utcSeconds);

            utcSeconds = 1;
            if (auctionDate != 0 && auctionPrice.bidCount > 0) {
                utcSeconds = auctionDate.toString();
                var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                d.setUTCSeconds(utcSeconds);

                this.setState({date: ((utcSeconds) - (Date.now()/1000))*1000});
            }

            const now = Math.round(Date.now() / 1000)
            var dNow = new Date(0); // The 0 there is the key, which sets the date to the epoch
            dNow.setUTCSeconds(now);

            this.setState({
                color: tokenColor,
                owner: tokenOwner,
                price: tokenPriceEth,
                creator: _NFT.creator.toString(),
                royalty: _NFT.royalty,
                currentBid: auctionPrice.currentBidAmount.toString(),
                startingBid: auctionPrice.startingBid.toString(),
                auctionActive: auctionActive,
                currentBidOwner: auctionPrice.currentBidOwner.toString(),
                auctionDuration: auctionPrice.duration.toString(),
                auctionStarted: auctionStarted,
                bidCount: auctionPrice.bidCount,
                dateMinted: dateMinted.toString(),
                utcSeconds: auctionDate.toString()
            })

            // if (this.state.auctionStarted && this.state.bidCount > 0) {
            //     this.state.countdownRef.current.start()
            // }

            this.RetrieveIPFS(tokenColor)

        } else {
            window.alert("Smart contract not deployed to detected network")
        }
    }

    fetchCurrencyData = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
        .then(response => {
            this.setState({ currentUsdValue: response.data.USD })

        })
        .catch(err => console.log(err))
    }

    async RetrieveIPFS(hash) {

        fetch("https://moonbear.mypinata.cloud/ipfs/"+hash)
          .then(response => response.json())
          .then((jsonData) => {
            // jsonData is parsed json object received from url

            var imageHash = jsonData.image.replace('ipfs://','');

            this.setState({
              metaImage: imageHash,
              metaName: jsonData.name,
              metaDescription: jsonData.description,
            });

          })
          .catch((error) => {
            // handle your errors here
            console.error(error)
          })
    }

	ETHtoUSD = (eth) => {
		return ((this.props.currentUsdValue * eth).toLocaleString(undefined, {maximumFractionDigits:2}))
	}

	bid= (id, price) => {
        const web3_utils = require('web3-utils');
        const _this = this;

        this.state.contract_auction.methods.bid(id, web3_utils.toWei(price)).send({from: this.state.account, value: web3_utils.toWei(price)})
        .once('receipt', function(){

            _this.setState({
                bidCount: _this.state.bidCount + 1,
                auctionStarted: true,
                currentBid: web3_utils.toWei(_this.state.bidPrice).toString()
            })

            if (_this.state.date == 0) {
                _this.setState({
                    date: 86400*1000
                })
                _this.state.countdownRef.current.start()
            }

            _this.setState({
                bidSubmitted: price
            })

         })
    }

    buy= () => {

        if (this.props.metamask == "false") {
            window.location.href = 'https://nft.moonbear.com/signin'; 
        } else {
            const web3_utils = require('web3-utils');
            const priceWithCommision = (parseInt(this.state.price)+1).toString()

            web3_utils.toWei(this.props.price)
            const _this = this

            this.state.contract.methods.buy(this.props.id).send({from: this.state.account, value: web3_utils.toWei(this.state.price), gas: 220000})
            .once('receipt', function(){
                _this.setState({
                    owner: _this.state.account,
                    price: 0
                })
            })
        }
    }

  	Price() {

        const web3_utils = require('web3-utils');
    
        if (!this.props.auctionStarted && this.props.price > 0) {
            return <div class="sell-card-section">
                <h2 className="current-bid">
                    {this.props.price.toString()} 
                    <span className="currency"><i class="fab fa-ethereum"></i></span> <span class="badge bg-outline-green">Fixed price</span>
                </h2>
                <span className="usd-bottom">(${this.ETHtoUSD(this.props.price)})</span>
                <p></p>
            </div>
        }
    }

  	Auction() {
        const web3_utils = require('web3-utils');


            if (this.state.auctionStarted && this.props.bidCount > 0) {
                return <div class="sell-card-section">
                    <h2 className="current-bid">{web3_utils.fromWei(this.state.currentBid.toString(), 'ether')} 
                        <span className="currency"><i class="fab fa-ethereum"></i></span> 
                        {(this.state.date > 0 && this.state.bidCount > 0) || (this.state.currentBid == 0) ? <span class="badge bg-outline">Auction</span> : <span class="badge bg-outline-faded">Auction Complete</span>}
                    </h2>
               
                    <Countdown renderer={props => 
                        <div class="auction-timer">

                            <div class="countdown-timer">
                                <span className="countdown-title">{props.hours}</span> <span class="bold">Hours</span>
                            </div>  

                            <div class="countdown-timer">{props.minutes} 
                                <span class="bold">Minutes</span>
                            </div> 

                            <div class="countdown-timer">{props.seconds} 
                                <span class="bold">Seconds</span>
                            </div>  

                            <div class="countdown-timer">{this.props.bidCount} 
                                <span class="bold">Bids</span>
                            </div>  
                        </div>
                    } key={this.state.date} onComplete={this.CountdownComplete} ref={this.state.countdownRef} autoStart={true} date={Date.now() + (parseInt(this.state.date))}>
            }
                    </Countdown>
                    <p></p>
                </div>
            } else if (this.state.auctionStarted && this.props.bidCount == 0) {
            return <div class="sell-card-section">
                <h2 className="current-bid">{web3_utils.fromWei(this.state.currentBid.toString(), 'ether')} 
                    <span className="currency"><i class="fab fa-ethereum"></i></span> 
                    {(this.state.date > 0 && this.props.bidCount > 0) || (this.state.bidCount == 0) ? <span class="badge bg-outline">Auction</span> : <span class="badge bg-outline-faded">Auction Complete</span>}
                </h2>

                <h4>Timer will begin after first bid.</h4>
                     <p></p>
                </div>
            }
        

    }

    AuctionBid() {
         const web3_utils = require('web3-utils');
         const currentBid = web3_utils.fromWei(this.props.currentBid.toString(), 'ether')

        if (this.state.date > 0 || (this.state.auctionStarted && this.state.bidCount == 0)) {
        return  <div className="sell-card-section">
            <form onSubmit={(event) => {
                event.preventDefault()
                this.bid(this.props.id, this.state.bidPrice)
                }}>
                <div className="eth-form">
                <input onChange={this.getBidPrice} type='text' className='form-control mb-1 bid-input' placeholder='0'/>
                <div class="input-eth"><i class="fab fa-ethereum"></i> ETH </div>
                </div>
                <p>Bids placed in the last 10 minutes will extend the auction by another 10 minutes.</p>

               {this.PlaceBidButton()}
            </form>
            {/*{this.GetMetaMask()}*/}
            <hr/>
            </div>
        }
    
    }

    PlaceBidButton() {
        const web3_utils = require('web3-utils');
        const currentBid = web3_utils.fromWei(this.props.currentBid.toString(), 'ether')

        if (this.props.metamask != "" && this.props.metamask == "true") {
            if (parseFloat(this.state.bidPrice) < parseFloat(currentBid)+parseFloat(0.001) || this.state.bidPrice == "") {
                return <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value={"bid "+(parseFloat(currentBid)+parseFloat(0.001)).toString()+" or more"} disabled/>
            } 

            if (parseFloat(this.state.bidPrice) >= parseFloat(currentBid)+parseFloat(0.001)) {
                return <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value={this.state.bidPrice.toString()+ " ETH - Place bid"}/>
            } 
        }

    }

    BuyButton(owner, id, price) {
        if (this.props.price > 0) {
            return <div class="sell-card-section">
            <form onSubmit={(event) => {
                event.preventDefault()
                this.buy(id, price)
            }}>
            <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Buy"/>
            </form> 
            <hr/>
            </div>
            
        }
    }

    GetMetaMask() {
        if (this.props.metamask == "false") {
            return <div class="get-metamask">
                <img src="/metamask.png" width="100px" height="auto"/>
                <span>Download and connect to metamask wallet to start buying and selling.</span>
                <input type="submit" className="btn btn-block btn-primary btn-lg w-100" value="Connect wallet" onClick={this.onClickInstall}/>
            </div>
        }
    }

    FooterData(owner, creator) {
        return <div className="sell-card-section">
            	<div class="auction-timer">

                    <div className="auction-section"><h5>Owner</h5>
                    	<h4 class="address"><i className="fas fa-user-tag"></i> <a href={"/"+this.props.owner}>{this.props.owner}</a></h4>
                    </div>

                    <div className="auction-section"><h5>Creator</h5>
                    	<h4 class="address"><i className="fas fa-user-edit"></i> <a href={"/"+this.props.creator}>{this.props.creator}</a></h4>
                    </div>
                   
                </div>

                <div class="auction-timer">

                    <div className="auction-section"><h5>Creator Royalty</h5>
                        <h4 class="address">{this.state.royalty}%</h4>
                    </div>

                    <div className="auction-section"><h5>Date minted</h5>
                        <h6>{dateFormat(this.state.dateMinted)}</h6>
                    </div>

                </div>

            </div>
    }

    alerts() {
	    if (this.state.bidSubmitted != "") {
	        return  <div class="sell-card-section">
	            <div class="alert alert-success alert-dismissible" role="alert">
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={this.clearAlerts}></button>
                    <div class="alert-icon">
                        <i class="far fa-fw fa-bell"></i>
                    </div>
                    <div class="alert-message">
                        {this.state.bidSubmitted} ETH - Bid submitted!
                    </div>
                </div>
	        </div>
	    }
    }

    MetaData() {

        const web3_utils = require('web3-utils');
    
        return <div class="sell-card-section">
        
            <h2 className="current-bid">{ this.props.name }</h2>
            <p className="large">{ this.props.description }</p>
            <hr />
        </div>
        
    }

	render() {
        return (

            <Row>

                <div className="col-md-8">
                    <div className="item">
                    	<a href={"/item/"+this.props.id}>
                        	<NFTImage tokenImage={this.props.metaImage} tokenFileType={this.props.fileType}/>
                        </a>
                    </div>
                </div>
      
                <div className="col-md-4">
                    <div className="single-side">

                    	{this.alerts()}   
                    	{this.MetaData()}
                    	{this.Price()} 
                    	{this.BuyButton()}
                        {this.Auction()}
                        {this.AuctionBid()}
                        
                        {this.FooterData()}
                    </div>
                </div> 
            </Row>
        );
    }

}

export default WideFeature;