
import React, { Component} from 'react';
import {Redirect} from 'react-router-dom';
import Web3 from 'web3'
import './App.css';
import Token  from '../abis/Token.json';
import TokenAuction  from '../abis/TokenAuction.json';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { browserHistory } from 'react-router';
import MetaMaskOnboarding from '@metamask/onboarding'
import {isMobile} from 'react-device-detect';
import ReactPlayer from 'react-player'

class Mint extends Component {

	async componentWillMount() {

		this.setState({
			mintStatus: "Mint"
		});

		await this.loadWeb3()
		await this.loadBlockchaindata()
		
		const pinataSDK = require('@pinata/sdk');
		const pinata = pinataSDK('0f4821963f69d9cda862', 'bce7b92bbb6f573171de3f8f084b09e3dae108257395627b945e3fc73f4d366a');

		pinata.testAuthentication().then((result) => {
		    //handle successful authentication here
		    //console.log(result);
		}).catch((err) => {
		    //handle error here
		    //console.log(err);
		});

		this.setState({
			sellType: 0
		});

		this.setState({
			placeHolderClass: "image-placeholder"
		});

	}

	async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            this.setState({metamask: "true"})
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            this.setState({metamask: "true"})
        } else {
            window.web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/723ee4d221864874b81cd8c6f7ae6b5f'))
            // window.web3 = new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:7545'))
             this.setState({metamask: "false"})
        }
    }

    //This will start the onboarding proccess
    onClickInstall = () => {
        const forwarderOrigin = 'http://localhost:3000';
        const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
          // onboardButton.innerText = 'Onboarding in progress';
          // onboardButton.disabled = true;
          //On this object we have startOnboarding which will start the onboarding process for our end user
          onboarding.startOnboarding();
    };

	async loadBlockchaindata() {
		const web3 = window.web3

		if (this.state.metamask == "true") {
            //load account
            const accounts = await web3.eth.getAccounts()
            if (accounts.length > 0) {
                const balance = await web3.eth.getBalance(accounts[0])
                this.setState({
                    account: accounts[0],
                    balance: balance
                });
            }
        } else {
            
        }

		const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

		if (networkData) {
			const abi = Token.abi
            const address = networkData.address
            const contract = new web3.eth.Contract(abi, address)

            const abi_auction = TokenAuction.abi
            const address_auction = networkAuctionData.address
            const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

            this.setState({contract: contract});
            this.setState({contract_auction: contract_auction});

		} else {
			window.alert("Smart contract not deployed to detected network")
		}

	}

	mint = (id, uri, price, royalty) => {

		const _this = this;

		console.log(id);
		console.log(uri);

		this.state.contract.methods.mint(id, royalty, this.state.contract_auction._address).send({from: this.state.account})
		.once('receipt', function(){
			_this.setState({
				mintStatus: "Mint complete!"
			})

			_this.setState({
				id: id
			});

			 _this.setState({
		      redirect: true
		    })

		}).once('error', function(error){ 
			_this.setState({
				mintStatus: "Mint",
				mintPending: false
			})
		 })
	}

	getPrice(i, e) {
		this.setState({
	  		sellPrice: e.target.value
		});
	}

	constructor(props) {
		super(props);
		this.state = { 
			account: '',
			balance: '',
			contract: null,
			totalSupply: 0,
			colors: [],
			owners: [],
			tokens: [],
			to: '',
			sellPrice: '',
			tokenToTransfer: '',
			selectedFile: null,
			getName: '',
			getDescription: '',
			getPrice:'',
			getRoyalty: '',
			file: null,
			filePreview: null,
			mintStatus: '',
			id: '',
			royalty_error: '',
			metamask: '',
			mintPending: false,
			mimeType: ''
		}

		this.onChangeHandler = this.onChangeHandler.bind(this)
  	}

  	SellForm(owner, id, price, key) {
  		if (this.state.account == owner) {
			return 	<form onSubmit={(event) => {
				event.preventDefault()
				this.allowBuy(id, price)
			}}>
				<input onChange={this.getPrice.bind(this, key)} type='text' className='form-control mb-1' placeholder='0.5'/>
				<input type="submit" className="btn btn-block btn-primary" value="sell"/>
			</form> 
  		} else {
  			
  		}
	  
	}

	BuyButton(owner, id, price) {
		if (this.state.account != owner && price > 0) {
			return <form onSubmit={(event) => {
				event.preventDefault()
				this.buy(id, price)
			}}>

			<input type="submit" className="btn btn-block btn-primary" value="buy"/>
			</form> 
		} else {

		}
	}

	RemoveFromSaleButton(owner, id, price) {
		if (this.state.account == owner && price > 0) {
			return <form onSubmit={(event) => {
				event.preventDefault()

				this.disallowBuy(id)

			}}>

			<input type="submit" className="btn btn-block btn-primary" value="Remove from sale"/>
			</form> 
		} else {

		}
	}

	onChangeHandler=event=>{

	if (this.checkMimeType(event) && this.checkFileSize(event)) {
	    this.setState({
	      selectedFile: event.target.files[0],
	      loaded: 0,
	      filePreview: URL.createObjectURL(event.target.files[0]),
	      placeHolderClass: "image-placeholder hidden"
	    })
	}
  }

	getName(i, e) {
		this.setState({
		getName: i.target.value
	});
	}

	getDescription(i, e) {
		this.setState({
		getDescription: i.target.value
	});
	}

	getPrice(i, e) {
		this.setState({
		getPrice: i.target.value
	});
	}

	getRoyalty(i, e) {
		this.setState({
		getRoyalty: i.target.value
		});

		if (i.target.value > 100) {
			this.setState({
				royalty_error: "Royalty must be less than 100%"
			});
		} else if (i.target.value % 1 != 0) {
			this.setState({
				royalty_error: "Royalty must be a whole number"
			});
		} else {
			this.setState({
				royalty_error: ""
			});
		}
	}

	setSellType(event) {
	    this.setState({
		sellType: event.target.value
		});
	  }

	  setAuction(e) {
	    this.setState({
			sellType: 2
		});
	  }

	  setFixedPrice() {
	    this.setState({
			sellType: 1
		});
	  }

	  setNoSale() {
	    this.setState({
			sellType: 0
		});
	  }

	checkMimeType=(event)=>{
	  //getting file object
	  let files = event.target.files 
	  //define message container
	  let err = ''
	  // list allow mime type
	 const types = ['image/png', 'image/jpeg', 'image/gif', 'video/mp4']
	  // loop access array
	  for(var x = 0; x<files.length; x++) {
	   // compare file type find doesn't matach
	       if (types.every(type => files[x].type !== type)) {
	       // create error message and assign to container   
	       err += files[x].type+' is not a supported format\n';
	     }

	     this.setState({mimeType: files[x].type})

	   };

	 if (err !== '') { // if message not same old that mean has error 
	      event.target.value = null // discard selected file
	      console.log(err)
	       return false; 
	  }
	 return true;

	}

	checkFileSize=(event)=>{
     let files = event.target.files
     let size = 30000000 
     let err = ""; 
     for(var x = 0; x<files.length; x++) {
     if (files[x].size > size) {
      err += files[x].type+'is too large, please pick a smaller file\n';
    }
  };
  if (err !== '') {
     event.target.value = null
     console.log(err)
     return false
}

return true;

}



	startMint = () => {

		this.setState({
			mintStatus: "Starting mint.. :)",
			mintPending: true
		});

	    const data = new FormData() 
	    data.append('file', this.state.selectedFile)
	    data.append('name', this.state.getName)
	    data.append('description', this.state.getDescription)
	    data.append('mimeType', this.state.mimeType)


	   const config = {     
		    headers: { 'content-type': 'multipart/form-data' }
		}

		const url = "https://nft.moonbear.com/api/upload"
		//const url = "http://localhost:3000/api/upload"

	    axios.post(url, data, config)
		.then(res => { // then print response status
			this.setState({
				mintStatus: "Pending approval (Metamask)",
				mintPending: true
			});
	      	this.mint(res.data.hash, res.data.uri, this.state.getPrice, this.state.getRoyalty)
	    })

// 	 const url = 'https://moonbear-marketplace.herokuapp.com/api/upload'

// const data = {
//         "title": 'Fred',
//         "lastName": 'Flintstone'
// }

// const options = {
//   method: 'POST',
//   headers: { 'content-type': 'application/x-www-form-urlencoded' },
//   url
// }

}
setRedirect = () => {
    this.setState({
      redirect: true
    })
  }


	    onMintedRedirect = (id) => {
	    	if (this.state.redirect) {
         return <Redirect to={this.state.account} />
     }
  }

  RemoveImage= () => {
  	this.setState({
  		filePreview: null
  	})
  }

ItemUpload () {

	if (this.state.filePreview == null) {
		return <div className="item-upload"><div className="image-placeholder">

                                            	<div class="upload-btn-wrapper">
												  <button class="btn btn-lg btn-primary">Upload your art</button>
												  <input type="file" name="myfile" onChange={this.onChangeHandler}/>
												</div>

                                            </div></div>
	}

	if (this.state.filePreview != "") {
		return  <div className="item-upload">
                                            {this.fileFormat()}

                                         
                                            <button className="remove-image" onClick={this.RemoveImage}>
                                            	<i class="fas fa-eraser"></i>

                                            </button>

                                        </div>
	}


 }

 fileFormat() {
 	if (this.state.mimeType == "video/mp4") {
 		return <ReactPlayer loop={true} width={"100%"} height={"100%"} onReady={() => this.setState({loaded: true})} muted={true} playing={true} url={this.state.filePreview} />
 	} else {
 		return <img src={this.state.filePreview} width="100%" />
 	}
 }

 MintButton() {
 	if (this.state.getName != "" && this.state.getDescription != "" && this.state.filePreview != null && this.state.getRoyalty < 100 && !this.state.mintPending) {
		return <input type="submit" className="btn btn-success btn-block form-control-lg btn-lg w-100" onClick={this.startMint} value={this.state.mintStatus} />
 	} else {
 		return <input type="submit" className="btn btn-success btn-block form-control-lg btn-lg w-100" onClick={this.startMint} value={this.state.mintStatus} disabled/>
 	}
 }

 RoyaltyValidation() {
 	if (this.state.royalty_error) {
 		return <div class="error">{this.state.royalty_error}</div>
 	}
 }

 MintForm() {
 	if (this.state.metamask != "" && this.state.metamask != "false") {
 	return <Row>
 	<div className="col-md-8">
                                        {this.ItemUpload()}
                                    </div>

                                    <div className="col-md-4">
                                        <div className="card shadow-sm">
                                       
                                            <form className="row g-3" onSubmit={(event) => {
												event.preventDefault()
											}}>

										<div class="sell-card-section">

											<label>
											<h4>Name</h4>
											<p>You gotta give it a name!</p>
											</label>
											<input  type='text' className='form-control mb-1 form-control-lg bid-input standard' placeholder='Name' onChange={this.getName.bind(this)} />
											
										</div>

									<div class="sell-card-section">
									<label>
									<h4>Description</h4>
									<p>Maybe a nice little story?</p>
									</label>
									<textarea type='text' className='form-control mb-1 form-control-lg bid-input standard' placeholder='Description' onChange={this.getDescription.bind(this)} />
			
									</div>
									
									<div class="sell-card-section">
									<label>
									<h4>Royalty</h4>
									<p>Set a royalty to receive a percentage of any future sales. Recommended: 5% - 15%</p>
									</label>
									<div className="eth-form">
					                <input onChange={this.getRoyalty.bind(this)} type='text' className='form-control mb-1 bid-input' placeholder='0'/>
					                <div class="input-eth">%</div>
					                {this.RoyaltyValidation()}
									</div>
									
									</div>
									
									<div class="sell-card-section">
									<label>
									<h4>Mint</h4>
									<p>Once you mint this, these values are permanently embedded into this artwork</p>
									</label>
									{this.MintButton()}
									</div>



							</form>
                                        

                                        </div>
                                    </div>
                                    </Row>
                                }
 }



       GetMetaMask() {

if (this.state.metamask != "" && this.state.metamask == "false") {
if (isMobile) {
            return <Row>
 	<div className="col-md-6">
 	<div class="get-metamask text-center">
                <img src="/metamask.png" width="100px" height="auto"/>
                <span>Your wallet will be used to securely store your digital goods and cryptocurrencies.</span>
                <a className="btn btn-block btn-primary btn-lg" href={"https://metamask.app.link/dapp/nft.moonbear.com/mint"}><i class="fas fa-user-lock"></i> Secure Sign in</a>
                <span className="small">Powered by <a href="https://metamask.io/">MetaMask</a>.</span>
            </div>
            </div>
            </Row>
        } else {
        	            return <Row>
 	<div className="col-md-6 offset-3">
 	<div class="get-metamask text-center">
                <img src="/metamask.png" width="100px" height="auto"/>
                <span>Your wallet will be used to securely store your digital goods and cryptocurrencies.</span>
                <input type="submit" className="btn btn-block btn-primary btn-lg" value="Secure Sign in" onClick={()=>{this.onClickInstall()}}/>
                <span className="small">Powered by <a href="https://metamask.io/">MetaMask</a>.</span>
            </div>
            </div>
            </Row>
        }

    }
        
    }


	render() {

		return (
			<div className="content">
				<div className="main">
				{this.onMintedRedirect("item/"+this.state.id)}
				<div className="container hero">
					<section class="py-5 text-center container">
					    <div class="row py-lg">
					      <div class="col-lg-6 col-md-8 mx-auto">
					        <h1 class="fw-light">Mint your art</h1>
					        <p class="lead text-muted">Mint your digital art into a unique NFT. Keep it as a part of your collection or sell it. </p>
					        {/* <p> */}
					        {/*   <a href="#" class="btn btn-primary my-2">Main call to action</a> */}
					        {/*   <a href="#" class="btn btn-secondary my-2">Secondary action</a> */}
					        {/* </p> */}
					      </div>
					    </div>
					  </section>
           
                            {this.MintForm()}    
                            {this.GetMetaMask()} 
        
                </div>

				<div className="container-fluid mt-5">
					<div className="row">
						<main role="main" className="col-md-4 offset-4 d-flex">
							<div className="content mr-auto ml-auto">


							



							</div>
						</main>
					</div>
				
				</div>

				</div>
	

			</div>

			);
	}
}

export default Mint;