
import React, { Component } from 'react';
import './App.css';
import ReactPlayer from 'react-player'

class NFTImage extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			loaded: false,
			started: false
		}
		this.myRef = React.createRef();
  	}

	video() {
        if (this.myRef.value == null) {

            return <ReactPlayer ref={this.myRef} loop={true} preload={true} playsinline={true} width={"100%"} height={"100%"} onReady={() => this.setState({loaded: true})} muted={true} playing={true} url={"https://moonbear.mypinata.cloud/ipfs/"+this.props.tokenImage} />
        } 
    }

  	fileFormat() {
  		  		
  		if (this.props.tokenFileType == "video/mp4") {
  			return <div className="video-player" style={this.state.loaded ? {} : {display: "none"}}>
  			{this.video()}
  			</div>
  		} else {
  			return <img alt="" style={this.state.loaded ? {} : {display: "none"}} src={"https://moonbear.mypinata.cloud/ipfs/"+this.props.tokenImage} width="100%" onLoad={() => this.setState({loaded: true})}/>
  		}
	  	
  	}

	render() {
		return (

			<div>
			
			{!this.state.loaded && <div className="image-preloader"><div className="loader"></div></div>}
			{this.fileFormat()}
			
			</div>
			)
	}

}

export default NFTImage;