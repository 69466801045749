
import React, { Component } from 'react';
import Web3 from 'web3'
import './App.css';
import Token  from '../abis/Token.json';
import TokenAuction  from '../abis/TokenAuction.json';
import Masonry from 'react-masonry-css'
import Countdown from 'react-countdown';
import NFTImage from './NFTImage';
import WideFeature from './WideFeature';
import axios from 'axios';


class Home extends Component {

	async componentWillMount() {

		// Load web3
		await this.loadWeb3()

		// Load blockchain data
		await this.loadBlockchaindata()

		await this.loadSelectedData(2)

		// Authenticate pinata (IPFS)
		const pinataSDK = require('@pinata/sdk');
		const pinata = pinataSDK('0f4821963f69d9cda862', 'bce7b92bbb6f573171de3f8f084b09e3dae108257395627b945e3fc73f4d366a');

		pinata.testAuthentication().then((result) => {
		    //handle successful authentication here

		}).catch((err) => {
		    //handle error here
		    console.log(err);
		});

		this.fetchCurrencyData();

	}

	// componentDidUpdate(prevProps, prevState) {
	//   console.log(prevState);
	// }

	async loadWeb3() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum)
            await window.ethereum.enable()
            this.setState({metamask: "true"})
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider)
            this.setState({metamask: "true"})
        } else {
            window.web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/723ee4d221864874b81cd8c6f7ae6b5f'))
            // window.web3 = new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:7545'))
             this.setState({metamask: "false"})
        }
    }

	async loadBlockchaindata() {

		const web3 = window.web3

		if (this.state.metamask == "true") {
            //load account
            const accounts = await web3.eth.getAccounts()
            if (accounts.length > 0) {
	            const balance = await web3.eth.getBalance(accounts[0])
	            this.setState({
	                account: accounts[0],
	                balance: balance
	            });
	        }
        } else {
            
        }

		const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

        if (networkAuctionData) {
        	
        } else {
			window.alert("Smart contract not deployed to detected network")
        }

		if (networkData) {

			// Load NFT contract
			const abi = Token.abi
            const address = networkData.address
            const contract = new web3.eth.Contract(abi, address)

            // Load auction contract
            const abi_auction = TokenAuction.abi
            const address_auction = networkAuctionData.address
            const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

            // Get total supply
            const totalSupply = await contract.methods.totalSupply().call()

			this.setState({
				contract: contract,
				contract_auction: contract_auction,
				totalSupply: totalSupply

			});

			for(var i = 0; i <= totalSupply-1; i++) {
				const _NFT = await contract.methods.tokenIdToNFT(i).call()
				const tokenOwner = await contract.methods.ownerOf(i).call() 
				const token = await contract.methods.tokens(i).call()
            	const auctionDate = await contract_auction.methods.getTimeleft(i).call()
            	const auctionPrice = await contract_auction.methods.tokenIdToAuction(i).call()
            	const auction = await contract_auction.methods.tokenIdToAuction(i).call()
            	const auctionStarted = await contract_auction.methods.started(i).call()
				const web3_utils = require('web3-utils');

				let tokenPriceEth = 0
				if (_NFT) {
					if (_NFT.price) {
						tokenPriceEth = web3_utils.fromWei(_NFT.price.toString(), 'ether')
					} else {
						tokenPriceEth = 0
					}
				}

				// Get auction time
				let utcSeconds = 1;

				let bidCount = 0;
	            if (auction !== null && auctionDate !== 0 && auctionPrice.bidCount > 0) {
	                utcSeconds = auctionDate.toString();
	                var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
	                d.setUTCSeconds(utcSeconds);
	                bidCount = auctionPrice.bidCount;
	            }

	            const auctionTimer = ((utcSeconds) - (Date.now()/1000))*1000;

				// this.setState({
				// 	colors: [...this.state.colors, token ],
				// 	owners: [...this.state.owners, tokenOwner]
				// })

				// Retrieve IPFS data
				this.RetrieveIPFS(token, tokenOwner, i, tokenPriceEth, _NFT.creator, auctionTimer, auction.currentBidAmount.toString(), bidCount, auctionStarted);

				
			}

		} else {
			window.alert("Smart contract not deployed to detected network")
		}
	}

	async loadSelectedData(i) {

		const web3 = window.web3

		const networkId = await web3.eth.net.getId()
        const networkData = Token.networks[networkId]
        const networkAuctionData = TokenAuction.networks[networkId]

		// Load NFT contract
		const abi = Token.abi
        const address = networkData.address
        const contract = new web3.eth.Contract(abi, address)

        // Load auction contract
        const abi_auction = TokenAuction.abi
        const address_auction = networkAuctionData.address
        const contract_auction = new web3.eth.Contract(abi_auction, address_auction)

		const _NFT = await contract.methods.tokenIdToNFT(i).call()


		const tokenOwner = await contract.methods.ownerOf(i).call() 
		const token = await contract.methods.tokens(i).call()

		const tokenURI = await contract.methods.tokenURI(i).call()
    	const auctionDate = await contract_auction.methods.getTimeleft(i).call()
    	const auctionPrice = await contract_auction.methods.tokenIdToAuction(i).call()
    	const auction = await contract_auction.methods.tokenIdToAuction(i).call()
    	const auctionStarted = await contract_auction.methods.started(i).call()

		const web3_utils = require('web3-utils');

		let tokenPriceEth = 0
		if (_NFT) {
			if (_NFT.price) {
				tokenPriceEth = web3_utils.fromWei(_NFT.price.toString(), 'ether')
			} else {
				tokenPriceEth = 0
			}
		}

		// Get auction time
		let utcSeconds = 1;

		let bidCount = 0;
        if (auction !== null && auctionDate !== 0 && auctionPrice.bidCount > 0) {
            utcSeconds = auctionDate.toString();
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(utcSeconds);
            bidCount = auctionPrice.bidCount;
        }

        const auctionTimer = ((utcSeconds) - (Date.now()/1000))*1000;

		// Retrieve IPFS data
		this.RetrieveSelectedIPFS(token, tokenOwner, i, tokenPriceEth, tokenURI, _NFT.creator, auctionTimer, auction.currentBidAmount.toString(), bidCount, auctionStarted, _NFT.royalty);

	}

	fetchCurrencyData = () => {
		axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
		.then(response => {
			this.setState({ currentUsdValue: response.data.USD })

		})
		.catch(err => console.log(err))
	}

	ETHtoUSD = (eth) => {
		return ((this.state.currentUsdValue * eth).toLocaleString(undefined, {maximumFractionDigits:2}))
	}


	bid = (id, price) => {
        const web3_utils = require('web3-utils');
        const _this = this;

        this.state.contract_auction.methods.bid(id, web3_utils.toWei(price)).send({from: this.state.account, value: web3_utils.toWei(price)})
        .once('receipt', function(){

            _this.setState({
                bidCount: _this.state.selectedToken.bidCount + 1,
                auctionStarted: true,
                currentBid: web3_utils.toWei(_this.state.selectedToken.bidPrice).toString()
            })

            if (_this.state.date == 0) {
                _this.setState({
                    date: 86400*1000
                })
                _this.state.selectedToken.countdownRef.current.start()
            }

            _this.setState({
                bidSubmitted: price
            })

         })
    }

    buy= () => {

    	if (this.state.metamask == "false") {
            window.location.href = 'https://nft.moonbear.com/signin'; 
        } else {
	        const web3_utils = require('web3-utils');
	        const priceWithCommision = (parseInt(this.state.selectedToken.price)+1).toString()

	        web3_utils.toWei(this.state.selectedToken.price)
	        const _this = this

	        this.state.contract.methods.buy(this.props.id).send({from: this.state.account, value: web3_utils.toWei(this.state.selectedToken.price)})
	        .once('receipt', function(){
	            _this.setState({
	                owner: _this.state.account,
	                price: 0
	            })
	        })
	    }
    }

	constructor(props) {
		super(props);
		this.state = { 
			account: '',
			balance: '',
			contract: null,
			totalSupply: 0,
			colors: [],
			owners: [],
			tokens: [],
			selectedToken: {
				image: '',
				contract_auction: '',
				currentBid: 0
			},
			to: '',
			sellPrice: '',
			tokenToTransfer: '',
			selectedFile: null,
			metaImage: [],
            metaName: [],
            metaFileType: [],
            metaDescription: [],
            breakpointColumnsObj: {
		        default: 3,
		        1100: 1,
		        700: 1,
		        500: 1
		      },
		    currentUsdValue: '',
		    metamask: false
		}

		this.myRef = React.createRef();

  	}

	Auction(key, date, currentBid, bidCount, fixedPrice, auctionStarted) {

	    const web3_utils = require('web3-utils');

	    if (auctionStarted) {
	    return <div>
	    	<h2 className="current-bid"></h2>
	        <Countdown renderer={props => 
	            <div className="auction-timer">

	            	<div className="auction-section"><h5>Bids </h5>
	            	<h3>{bidCount}</h3>
	            	</div>

	            	<div className="auction-section">
	            	<h5>Current bid </h5>
	            	<h3><i className="fab fa-ethereum"></i> {web3_utils.fromWei(currentBid, 'ether')} ETH</h3>
	            	</div>

	            	{ auctionStarted && bidCount > 0 &&
	            	<div className="auction-section"><h5>Ending in </h5>
	            	<h3><i className="fas fa-stopwatch"></i> {props.hours}h {props.minutes}m {props.seconds}s</h3>
	            	</div>
	            }
	               
	            </div>} 

	            key={key} ref={this.state.countdownRef} autoStart={true} date={Date.now() + (parseInt(date))}>
	                
	        </Countdown>
	             
	    </div>
	    }
	}

    FixedPrice(key, fixedPrice, auctionStarted) {
    
        if (!auctionStarted && fixedPrice > 0) {
	        return <div>
	        	<h2 className="current-bid"></h2>
	        	<div className="auction-timer">
	            	<div className="auction-section"><h5>Fixed price </h5>
	            	<h3><i className="fab fa-ethereum"></i> {fixedPrice} ETH <span className="usd">(${this.ETHtoUSD(fixedPrice)})</span></h3>

	            	</div>
	               
	            </div>
	        </div>
    	}
    }

    MetaData(key, owner, creator) {

        return <div key={key}>
        	<h2 className="current-bid"></h2>

        	<div className="auction-timer">
            	<div className="auction-section"><h5>Owner</h5>
            	<h4 className="address"><i className="fas fa-user-tag"></i> <a href={owner}>{owner}</a></h4>
            	</div>

            	<div className="auction-section"><h5>Creator</h5>
            	<h4 className="address"><i className="fas fa-user-edit"></i> <a href={creator}>{creator}</a></h4>
            	</div>
            </div>
        </div>
    }

   async RetrieveIPFS(token, owner, id, price, creator, auctionDate, currentBid, bidCount, auctionStarted) {

        fetch("https://moonbear.mypinata.cloud/ipfs/"+token)
          .then(response => response.json())
          .then((jsonData) => {

            // jsonData is parsed json object received from url
            var imageHash = jsonData.image.replace('ipfs://','');

            this.setState({
				tokens: [ ...this.state.tokens, 
				{ 
					owner: owner, 
					color: token, 
					id: id, 
					price: price, 
					image: imageHash,
					name: jsonData.name,
					description: jsonData.description,
					creator: creator,
					date: auctionDate,
					currentBid: currentBid,
					bidCount: bidCount,
					auctionStarted: auctionStarted,
					fileType: jsonData.fileType
				}]
			})


        }).catch((error) => {
        	// error
        })
    }

    async RetrieveSelectedIPFS(token, owner, id, price, uri, creator, auctionDate, currentBid, bidCount, auctionStarted, royalty) {
        fetch("https://moonbear.mypinata.cloud/ipfs/"+token)
          .then(response => response.json())
          .then((jsonData) => {

            // jsonData is parsed json object received from url
            var imageHash = jsonData.image.replace('ipfs://','');

            this.setState({
				selectedToken: 
				{ 
					owner: owner, 
					color: token, 
					id: id, 
					price: price, 
					uri: uri,
					image: imageHash,
					name: jsonData.name,
					description: jsonData.description,
					creator: creator,
					date: auctionDate,
					currentBid: currentBid,
					bidCount: bidCount,
					auctionStarted: auctionStarted,
					royalty: royalty,
					fileType: jsonData.fileType
				}
			})

        }).catch((error) => {
        	// error
        })

        


    }

    Badge(date, auctionStarted, bidCount, fixedPrice) {

    	if ((date <= 0 && auctionStarted && bidCount > 0)) {
    		return <div><span className="badge bg-outline">Auction Complete</span></div>
    	}

    	if (!auctionStarted && fixedPrice > 0) {
    		return <div><span className="badge bg-outline-green">Fixed price</span></div>
    	}

    	if ((date > 0 && auctionStarted) || (bidCount == 0 && auctionStarted)) {
    		return <div><span className="badge bg-outline">Auction</span></div>
    	}

    	if (!auctionStarted && fixedPrice == 0) {
    		return <div><span className="badge bg-outline-faded">Not for sale</span></div>
    	}

    }

    MasonryObject() {

 
    	if (this.state.tokens.length > 0) {
    	return <div data-masonry='{"percentPosition": true }'>
					  		<Masonry
						  	breakpointCols={this.state.breakpointColumnsObj}
						  	className="my-masonry-grid"
						  	columnClassName="my-masonry-grid_column col-md-4">
					  		{
								this.state.tokens.map((token, key) => {
						     		return (
									<div className="card" key={key}>
										<a href={"/item/"+token.id}>
											{(this.myRef.value == null) && <NFTImage ref={this.myRef} tokenImage={token.image} tokenFileType={token.fileType}/>}
										</a>
										        	
										<div className="card-body">
											<h2 className="card-title"><a href={"/item/"+token.id}>{token.name}</a></h2>
									    	<p className="card-description">{token.description}</p>
									        {this.Badge(token.date, token.auctionStarted, token.bidCount, token.price)}
									        <hr/>
							            	{this.Auction(key, token.date, token.currentBid, token.bidCount,token.price,token.auctionStarted)}
							            	{this.FixedPrice(key, token.price, token.auctionStarted)}
											{this.MetaData(key, token.owner, token.creator)}

											<hr/>
										</div>
									</div>
									    )
					     		})
			     			}
							</Masonry>
					  		</div>
					  	}
    }

	render() {
		return (
			<div className="wrapper">
				<div className="main">
					<div className="content">
					<section className="py-5 text-center container hero">
					    <div className="row py-lg">
					      <div className="col-lg-12 col-md-12 mx-auto">
					        <h1 className="fw-light">Discover, collect, and sell Pocket Build NFT</h1>
					        <p className="lead text-muted">What is an NFT? The information recorded in an NFT essentially lets a creator digitally "autograph" their art. So even if an image or piece of music has been shared hundreds of times, if you buy the NFT, you're buying something unique!</p>
					      </div>
					    </div>
					  </section>
						<div className="container p-0">

							<div class="home-title"><h2 className="fw-light featured">Featured NFT</h2></div>
							
{
							<WideFeature metaImage={this.state.selectedToken.image} 
							id="2"
							contract_auction={this.state.selectedToken.contractAuction} 
							auctionStarted={this.state.selectedToken.auctionStarted} 
							bidCount={this.state.selectedToken.bidCount} 
							currentBid={this.state.selectedToken.currentBid} 
							date={this.state.selectedToken.date} 
							price = {this.state.selectedToken.price}
							owner = {this.state.selectedToken.owner}
							creator = {this.state.selectedToken.creator}
							royalty = {this.state.selectedToken.royalty}
							metamask = {this.state.metamask}
							name = {this.state.selectedToken.name}
							description = {this.state.selectedToken.description}
							currentUsdValue = {this.state.currentUsdValue}
							metamask = {this.state.metamask}
							fileType = {this.state.selectedToken.fileType} />}

							
							<br/><br/>



					  		{this.MasonryObject()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;